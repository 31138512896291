import React from 'react';

function Homily() {

    
    return (
        <div className='SundayHomily'>
            <main>
                <a className='MainBTN' href={`/assets/Homily/2025-2-9.docx`} download>
                📄Read Homily  
                <p>02/09/2025</p>
                </a>

                <a className='MainBTN' href={`/assets/Bulletin/2025/2025-2-9.pdf`} target="_blank" rel="noopener noreferrer">📄View Bulletin 
                <p>02/09/2025</p>
                 </a>
              
            </main>

        </div>
    );
}

export default Homily;